@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  font-family: "Poppins" !important;
  line-height: 1;
  background-color: #fdfdfd;
}

#basic-navbar-nav a > span,
#basic-navbar-nav .dropdown-item > span {
  margin-left: 5px;
}

#basic-navbar-nav .dropdown-item:hover {
  cursor: pointer;
}

footer {
  padding-top: 20px;
  position: relative;
  -moz-box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  -webkit-box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

hr {
  border-top: 2px solid #dee1e1;
}

.product-icon {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.product-icon:hover {
  color: rgb(66, 64, 66) !important;
}

.product-icon.active {
  color: #ff5b00 !important;
}

.product-icon.active .rounded-circle {
  border: 3px solid #ff5b00 !important;
}

.product-icon img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.product-icon:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.search-product input::placeholder {
  color: #a2a5a5;
}

.search-product input,
.search-product .input-group-text {
  border-color: #dee1e1;
}

.search-product input:focus {
  box-shadow: none;
  border-color: #dee1e1;
}

.search-flight fieldset {
  border: 1px solid #dee1e1;
  padding: 20px 20px;
  cursor: pointer;
}

.search-flight fieldset.disabled {
  cursor: not-allowed;
}

.search-flight fieldset:first-child {
  border-radius: 10px 0 0 10px;
}

.search-flight fieldset:last-child {
  border-radius: 0 10px 10px 0;
}

.search-flight fieldset legend {
  width: auto;
  float: none !important;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  color: #3e4141;
  margin-bottom: 0;
  padding: 0 5px;
}

.search-flight input {
  padding-bottom: 15px;
}

.search-flight input:focus {
  box-shadow: none;
}

.flight-schedule > div {
  width: 100px;
}

.radio-cabin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #dee1e1;
  color: #8e9191;
}

.radio-cabin .form-check-input:checked[type="radio"]:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 89, 13, 0.25);
}

.radio-cabin .form-check-input:checked[type="radio"] {
  background-color: #ff5b00;
  border: #ff5b00;
}

.radio-cabin .form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}

.first-vertical {
  border-right: 1px solid #cacdcd;
}

.passanger {
  padding: 13px 0;
  border-bottom: 1px solid #dee1e1;
}

.button-passanger {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-passanger .value-passanger {
  width: 40px;
  text-align: center;
}

.button-passanger .btn {
  width: 25px;
  height: 25px;
  font-size: 12px;
  border-radius: 50%;
  padding: 0;
  padding-left: 1px;
  color: #fff;
  font-weight: bold;
}

.flight-tab {
  margin-bottom: 1rem;
}

.flight-tab .title {
  padding: 20px 15px;
  border-bottom: 1px solid #dee1e1;
}

.flight-tab .flight-list {
  border-left: 2px solid #dee1e1;
  padding: 20px 10px;
  box-shadow: 2px 4px 4px 0px #9999991a;
  opacity: 0.5;
}

.flight-tab .flight-list .circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 8px;
  background: #ff5b00;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
}

.flight-tab .flight-list.active {
  opacity: 1;
  border-left: 2px solid #ff5b00;
}

.flight-item .line-flight {
  padding: 0.25rem;
  border-bottom: 2px solid #dee1e1;
}

.flight-item .tab-flight-detail {
  border-bottom: none;
}

.flight-item .tab-flight-detail .nav-link {
  color: #ff5b00;
}

.flight-item .tab-flight-detail .nav-link:focus,
.flight-item .tab-flight-detail .nav-link:hover {
  border: none;
  border-bottom: 1px solid #ff5b00;
}

.flight-item .tab-flight-detail .nav-link.active {
  border: none;
  border-bottom: 1px solid #ff5b00;
}

.city-modal {
  height: 600px;
  overflow-y: auto;
}

.city-modal .list {
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.city-modal .list .city-detail .city-desc {
  font-size: 14px;
  font-weight: lighter;
  color: #8e9191;
}

.custom-switch.xl .form-check-input {
  padding: 10px;
  width: 40px;
}

.bg-primary-2 {
  background-color: #ff973c14;
}

.custom-switch .form-check-input:checked {
  background-color: #ff5b00;
  border-color: #ff5b00;
  background-image: #ff5b00;
}

.custom-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
}

.custom-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}

.section2 .nav-tabs {
  overflow-x: auto;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.section2 .nav-tabs::-webkit-scrollbar {
  display: none;
}

.section2 .nav-tabs .nav-item {
  white-space: nowrap;
}

.section2 .nav-tabs .nav-item button {
  border: none;
  color: #8e9191;
  font-size: 18px;
  padding: 8px 0;
  margin-right: 20px;
}
.section2 .nav-tabs .nav-item button.active {
  border-bottom: 3px solid #ff5b00;
  color: #221d23;
}

.section2 .package > img,
.section3 .package > img {
  height: 150px;
  object-fit: cover;
  object-position: 100% 100%;
}

.section2 .package .card-body {
  height: 120px;
}

.section3 .package .card-body {
  height: 150px;
}

.section3 .package > img {
  height: 220px;
  object-fit: cover;
  object-position: 100% 100%;
}

.section2 .package:hover,
.section3 .package:hover,
.section1-destination .package:hover,
.section2-destination .package:hover {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.section2 .package,
.section3 .package,
.section4 .package,
.section1-destination .package:hover .section2-destination .package:hover {
  transition: box-shadow 0.2s;
  overflow: hidden;
}

.section4 .package > img {
  height: 400px;
  object-fit: cover;
  object-position: 100% 100%;
  transition: transform 0.2s;
}

.section4 .package > img:hover {
  transform: scale(1.2);
}

.section4 .package .description,
.section4 .package .description-link {
  position: absolute;
  padding: 20px;
}

.section4 .package .description-link {
  bottom: 0;
}

.overflow-card {
  flex-wrap: unset;
  overflow-x: auto;
}

.overflow-card::-webkit-scrollbar {
  display: none;
}

.section5 .partners-tab {
  justify-content: center;
  border-bottom: none;
  margin-bottom: 2rem;
}

.section5 .tab-content .overflow-card {
  justify-content: center;
}

.section5 .tab-content {
  margin-bottom: 4rem;
}

.section5 .partners-tab .nav-item {
  border-bottom: 1px solid #e8edf1;
}

.section5 .partners-tab .nav-item .nav-link {
  color: #4f5e71;
  font-weight: 400;
}

.section5 .partners-tab .nav-item .nav-link.active {
  color: #ff5b00;
  font-weight: 600;
  border: none;
  border-bottom: 2px solid #ff5b00;
}

.section5 .img-partners {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.carousel-indicators {
  bottom: -50px !important;
  margin-bottom: 20px !important;
}

.carousel-indicators button {
  width: 12px !important;
  height: 12x !important;
  background-color: #cacdcd !important;
  border-radius: 12px !important;
  border: none !important;
}

.carousel-indicators button.active {
  width: 24px !important;
  background-color: #ff5b00 !important;
}

.section1-destination .package > img {
  height: 400px;
  object-fit: cover;
  object-position: 100% 100%;
}

.section1-destination2 .package > img {
  height: 312px;
  object-fit: cover;
  object-position: 100% 100%;
}

.section1b {
  background: linear-gradient(
    160.81deg,
    #ff8328 25.07%,
    #f76800 85.44%,
    #f76800 85.44%
  );
  height: 420px;
}

.section1c {
  background: linear-gradient(
    160.81deg,
    #003a90 25.07%,
    #002761 85.44%,
    #002761 85.44%
  );
  height: 470px;
}

.section1b .bg {
  background-image: url("./assets/images/landingPage/indonesia.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
}

.section1c .bg {
  background-image: url("./assets/images/landingPage/indonesia.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
}

img .logoWa {
  width: 150px;
}
.group-all {
  position: relative;
}

.group-le {
  display: flex;
  overflow-y: hidden;
}

.group-le::-webkit-scrollbar {
  display: none;
}

.group-le {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.item-le {
  min-width: 150px;
  max-width: 150px;
  margin-right: 5px;
  height: 100%;
}

.item-le .card-img-top {
  height: 140px;
  object-fit: cover;
  object-position: 100% 100%;
}

.item-le .package .card-body {
  height: 130px;
}

.item-le .item-le-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 1rem;
}

.btn-group {
  width: 50px;
  height: 100px;
  position: absolute;
  z-index: 1;
  align-items: center;
  bottom: 40%;
  cursor: pointer;
  background-repeat: no-repeat;
  opacity: 0.6;
}

.group-banner .btn-group {
  height: 50px;
  bottom: 40%;
}

.btn-group.prev-btn {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
  opacity: 1;
}

/* .btn-group.prev-btn:hover {
  opacity: 1;
}
.btn-group.next-btn:hover {
  opacity: 1;
} */

.btn-group.next-btn {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  right: 0;
  opacity: 1;
}

.filter-search .accordion .accordion-item {
  border: none;
}

.filter-search .accordion .accordion-item:not(:last-child) {
  border-bottom: 1px solid #dee1e1;
}

.filter-search .accordion .accordion-item .accordion-header .accordion-button {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  font-weight: 500;
}

.filter-search .accordion .accordion-item .accordion-body {
  padding: 0 0 1.5rem 0;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:focus,
.filter-search .accordion .accordion-item .accordion-header .accordion-button,
.filter-search
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  border: none;
  box-shadow: none;
  color: #ff5b00;
}

.filter-search .accordion .accordion-item .accordion-body .form-check-reverse {
  text-align: left;
  font-weight: normal;
  align-items: center;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .form-check-reverse:not(:last-child) {
  margin-bottom: 15px;
}

.filter-search .accordion .accordion-item .accordion-body .form-check-input,
.radio-group .form-check-input {
  width: 1.3em;
  height: 1.3em;
  margin-top: 0;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .form-check-input:checked,
.radio-group .form-check-input:checked {
  background-color: #ff5b00;
  border-color: #ff5b00;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .form-check-input:focus,
.radio-group .form-check-input:focus {
  border-color: #ff5b00;
  box-shadow: 0 0 0 0.25rem rgba(255, 166, 0, 0.25);
}

.filter-search
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/icons/arrowUp.svg");
  transform: scaleY(1);
}

.filter-search
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button::after {
  background-image: url("./assets/icons/arrowUp.svg");
  transform: scaleY(-1);
  height: 32px;
  width: 32px;
  background-size: auto;
}

.filter-search .accordion .accordion-item .accordion-body .multi-range-slider {
  box-shadow: none;
  border: none;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .multi-range-slider
  .bar-inner {
  background-color: #ff973c;
  box-shadow: none;
  border: none;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .multi-range-slider
  .bar-left {
  padding: 2px 0px;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .multi-range-slider
  .bar-right,
.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .multi-range-slider
  .thumb::before {
  box-shadow: none;
  border: 1px solid #ff5b00;
  background-color: #fff;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .multi-range-slider
  .thumb
  .caption
  * {
  display: none;
}

.filter-search .accordion .accordion-item .accordion-body .line-middle {
  min-width: 10px;
  display: block;
  border-bottom: 1px solid #dee1e1;
}

.filter-search .accordion .accordion-item .accordion-body .price span,
.filter-search .accordion .accordion-item .accordion-body .price input {
  font-size: 14px;
  font-weight: 400;
}

.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .price
  span.input-group-text {
  background-color: transparent;
  border-right: none;
}

.filter-search .accordion .accordion-item .accordion-body .price .form-control,
.filter-search .accordion .accordion-item .accordion-body .price span,
.filter-search
  .accordion
  .accordion-item
  .accordion-body
  .price
  .form-control:focus {
  box-shadow: none;
  border: 1px solid #dee1e1;
}

.pagination .page-item.active .page-link {
  background-color: #ff5b00;
  border-color: #ff5b00;
  border-radius: 50%;
}

.pagination .page-item {
  padding: 0 5px;
}

.pagination .page-item .page-link {
  line-height: 19px;
}

.pagination .page-item.disabled .page-link {
  border: none;
}

.pagination .page-item .page-link:focus {
  box-shadow: none;
}

.pagination .page-item a {
  color: #666969;
  border: none;
}

.detail-product .carousel img {
  object-fit: cover;
}

.detail-description {
  font-family: "Poppins" !important;
  font-size: 14px !important;
}
.timeline {
  position: relative;
  padding: 0;
  margin: 0;
}

.tl-item {
  border-radius: 3px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}

.tl-item > * {
  padding: 0 10px;
}

.tl-item {
  padding-bottom: 20px;
  height: 100px;
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
  content: "";
  position: absolute;
  border-color: #ff5b00;
  background-color: #ff5b00;
  border-width: 2.5px;
  border-style: solid;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.tl-dot:after {
  width: 0;
  height: auto;
  top: 40px;
  bottom: -10px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
  padding: 30px 0;
}

.tl-item:first-child .tl-dot::before {
  background-image: url("./assets/icons/pinFilled.svg");
  background-size: cover;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  top: 10px;
}

.tl-item:last-child {
  padding-bottom: 0;
}

.tl-content p:last-child {
  margin-bottom: 0;
}

.tl-content {
  padding-top: 10px;
}

.tl-date {
  font-size: 1em;
  padding-bottom: 13px;
}

.modal-search {
  min-width: 900px;
}

.modal {
  padding-left: 0 !important;
}

.font-sans {
  font-family: "Open Sans";
  font-size: 14px;
}

.font-sans-force {
  font-family: "Open Sans";
}

.important-policy {
  line-height: 21px;
}

.item-important-policy {
  color: #000;
}

.modal-search .row > div {
  padding: 20px;
}

.modal-search .vertical-line {
  border-right: 1px dashed #dee1e1;
}

.react-datepicker {
  border: 1px solid #dee1e1;
  border-radius: 10px;
  width: 100%;
  padding: 40px 20px;
  font-family: inherit;
}

.react-datepicker__navigation {
  top: 40px;
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  border-color: #a8a8a8;
}

.react-datepicker__navigation--previous {
  /* left: 2.5rem; */
}

.react-datepicker__navigation--next {
  /* right: 2.5rem; */
}

.react-datepicker__month-container {
  float: none;
  min-height: 380px;
  margin-top: 40px;
  /* padding: 0%; */
}

.react-datepicker__header {
  background-color: transparent;
  border: none;
}

.react-datepicker__current-month {
  padding-bottom: 30px;
  font-weight: 500;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  height: 2.3rem;
  width: 2.3rem;
  line-height: 2.3rem;
  font-size: 14px;
}

.react-datepicker__current-month {
  font-size: 18px;
}

.react-datepicker__day-names {
  padding-bottom: 20px;
}

.react-datepicker__day-names .react-datepicker__day-name,
.react-datepicker__day.react-datepicker__day--disabled {
  color: #a8a8a8;
}

.react-datepicker__day.react-datepicker__day--disabled {
  text-decoration: line-through;
}

.react-datepicker__day.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #ff5b00;
  font-weight: bold;
}

/* .react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: transparent;
  color: #000;
} */
.react-datepicker__day:hover {
  border-radius: 50%;
}

.select-input {
  width: 100%;
}

.select-input .select__value-container {
  padding: 10px;
}

.select-input .select__value-container::before {
  content: "";
  width: 30px;
  height: 30px;
  left: 10px;
  background-image: url("./assets/icons/personOutline.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}

.select-input .select__value-container,
.select-input .select__placeholder,
.select-input .select__single-value {
  padding-left: 20px;
}

.select-input .select__control.select__control--is-focused {
  border: 1px solid #ff5b00;
  box-shadow: none;
}

.control-input {
  padding: 10px 15px;
}

.control-input::placeholder,
.select__placeholder {
  color: hsl(0, 0%, 50%);
  font-weight: normal;
}

.control-input:focus {
  box-shadow: none;
  border: 1px solid #ff5b00;
}

.select__indicator {
  padding: 5px 1px !important;
}

.select__indicator-separator {
  display: none;
}

.select__control {
  padding: 4px 4px;
  border-radius: 0.375rem !important;
}

.select__control.select__control--is-focused {
  border-color: #ff5b00 !important;
  box-shadow: none;
}

.order-summary img {
  width: 100px;
  height: 80px;
  object-fit: cover;
}

.order-summary .d-block {
  height: 80px;
}

.order-summary .text-title {
  overflow: hidden;
  height: 49px;
}

.btn-outline-primary:hover {
  color: #fff;
}

.booking-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.payment-tab .nav-link.active {
  color: #ff5b00 !important;
  border: none;
  border-bottom: 2px solid #ff5b00;
  font-weight: 500;
}

.payment-tab .nav-link {
  color: #221d23;
  font-weight: 400;
  transition: none;
}

.payment-tab .nav-link.active:hover {
  border-bottom: 2px solid #ff5b00 !important;
}

.payment-tab .nav-link:hover {
  border: none !important;
}

.flight .accordion-item {
  border: none;
}

.flight .accordion-item .accordion-header .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.flight .accordion-item .accordion-header .accordion-button {
  background-color: transparent;
  color: #000;
  padding: 1rem 0;
}

.timeline-flight .item {
  display: flex;
  font-size: 14px;
}

.timeline-flight .item:first-child {
  min-height: 150px;
}

.timeline-flight .item:last-child {
  margin-top: -15px;
  margin-bottom: 15px;
}

.timeline-flight .item .event {
  padding-left: 1rem;
  width: 60%;
}

.timeline-flight .item .date-time {
  padding-right: 1rem;
  width: 40%;
  text-align: right;
}

.timeline-flight .item:first-child .date-time {
  border-right: 3px solid #ff5b00;
}

.timeline-flight .item,
.timeline-flight .item .date-time,
.timeline-flight .item .event {
  position: relative;
}

.timeline-flight .item:first-child .date-time::after,
.timeline-flight .item:first-child .event::before,
.timeline-flight .item .flight,
.timeline-flight .item .duration-left {
  position: absolute;
}

.timeline-flight .item:first-child .date-time::after,
.timeline-flight .item:first-child .event::before {
  content: "";
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #ff5b00;
  border-radius: 50%;
  display: inline-block;
}

.timeline .flight {
  left: 1rem;
  top: 45%;
  background-color: #f8f8f8;
  padding: 0.5rem;
  border-radius: 5px;
  color: #666969;
  font-size: 14px;
}

.timeline .duration-left {
  top: 50%;
  right: 1rem;
}

.timeline-flight .item:first-child .date-time::after {
  right: -9px;
  top: 0;
}

.timeline-flight .item:first-child .event::before {
  bottom: 0;
  left: -9px;
}

.timeline-flight .item .date-time .time,
.timeline-flight .event .title {
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.timeline-flight .item .date-time .date,
.timeline-flight .event .description {
  font-weight: normal;
}

.badge-city {
  background-color: #f2f2f2;
  color: #7a7d7d;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
}

.judul {
  font-size: 20px;
}

.image-information {
  height: 80px;
  width: 80px;
  object-fit: cover;
  object-position: center;
}

.line-information {
  border-top: 2px dashed #dee1e1;
}

.receipt .card-body {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.ta-image {
  height: 424px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.partners-number {
  counter-reset: my-awesome-counter;
  list-style: none;
}

.partners-number li {
  margin: 0 0 0.5rem 0;
  counter-increment: my-awesome-counter;
  position: relative;
}

.partners-number li::before {
  content: counter(my-awesome-counter);
  font-size: 1rem;
  position: absolute;
  --size: 25px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  background: #fde9df;
  border-radius: 50%;
  text-align: center;
}

.wizard-form {
  min-height: 700px;
}

.step-nav {
  justify-content: center;
}

.step-nav .container-step-nav {
  min-width: 200px;
}

.step-nav .container-step-nav .number {
  border-radius: 50%;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  background: #cacdcd;
  color: #fdfdfd;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.step-nav .container-step-nav.active .number {
  background-color: #ff5b00;
  font-weight: bold;
}

.step-nav .container-step-nav {
  position: relative;
}

.step-nav .container-step-nav:not(:last-child)::after {
  content: "";
  width: 100%;
  border-bottom: 3px solid #b6b9b9;
  position: absolute;
  top: 30%;
  z-index: -1;
}

.step-nav .container-step-nav.active:not(:last-child)::after {
  border-bottom: 3px solid #ff5b00;
}

.step-nav .container-step-nav.active .label.active {
  color: #ff5b00;
  font-weight: bold;
}

.card-step {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.card-step .card-body {
  padding: 2rem 3rem;
}

.radio-group .form-check {
  line-height: 1.4rem;
}

.radio-group .form-check .form-check-label {
  padding-left: 10px;
}

.dropzone {
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  text-align: center;
  width: 400px;
  height: 180px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  border: 1px dashed #ff6f14;
  background-color: #ff832808;
  padding: 2rem 1.5rem;
}

.dzu-preview {
  margin-top: 50px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.dzu-preview .dzu-previewContainer {
  height: 100px;
  margin-bottom: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.dzu-preview .dzu-previewContainer .dzu-previewStatusContainer {
  width: 40px;
  height: 100%;
  vertical-align: middle;
  display: flex;
}

.dzu-preview
  .dzu-previewContainer
  .dzu-previewStatusContainer
  .dzu-previewButton {
  color: #fff;
  width: 30px;
  height: 30px;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.dzu-preview .dzu-previewContainer img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  padding: 10px;
}

.custom-checkbox {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.custom-checkbox .form-check {
  border: 1px solid #f0f0f0;
  text-align: center;
  margin-top: 10px;
  position: relative;
}

.custom-checkbox-2 .form-check input {
  z-index: 1;
}

.custom-checkbox .form-check input {
  position: absolute;
  z-index: 2;
  right: 30px;
  top: 10px;
}

.custom-checkbox .form-check label {
  padding: 10px;
  width: 100%;
}

.profile-image {
  width: 80px;
  height: 80px;
  background-color: #dee1e1;
  border-radius: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.profile-image img {
  width: 80px;
  height: 80px;
  object-fit: fill;
  object-position: center;
}

.icon {
  color: #ff6f14;
}

.img-payment {
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
}

.img-qr {
  width: 100%;
  height: 100%;
}

.position-relative .password-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

.position-relative .password-icon img {
  width: 20px;
  height: 20px;
}

.body-destination {
  /* height: 300px !important; */
  margin-top: -20px;
}

.img-destination {
  width: 100%;
  height: 30vh;
  object-fit: cover;
  object-position: 100% 100%;
  border-radius: 10px;
}

.about-destination {
  height: 100% !important;
}

.destination-picture {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 100% 100%;
  border-radius: 10px;
}

.e-ticket .modal-dialog {
  width: 900px;
  max-width: none;
}

.e-ticket-header-type {
  background-color: #ff6f14;
  color: #ffffff;
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.e-ticket-header-logo {
  width: 100px;
}

.sticky-price {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
}

.additional-page li {
  margin-bottom: 5px;
}

.additional-page p {
  line-height: 1.3;
  font-size: 14px;
}

.investor-table td {
  padding: 12px 8px;
  font-size: 0.875em;
  text-align: left;
}

.investor-table td {
  border-bottom: 1px solid #dee1e1;
}

.investor-table tr:last-of-type td {
  border-bottom: none !important;
}

.about-us-image {
  width: 100%;
  overflow-y: hidden;
}

.additional-page .travel-img {
  max-height: 250px;
}

.additional-page .large-space {
  margin-bottom: 5rem !important;
}

.additional-page .values img {
  margin-bottom: 1rem;
  max-height: 100px;
}

.design-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.design-section .timeline {
  height: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 3px 1fr;
  flex-direction: column;
}

.design-section .timeline-content {
  text-align: right;
  border-radius: 5px;
  padding: 0;
  transition: 0.4s ease;
  overflow-wrap: break-word !important;
  border-radius: 6px;
  min-height: 140px;
}

.design-section .timeline-content h5 {
  color: #ff5b00;
  padding: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.design-section .timeline-content p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

.design-section .timeline-content ul {
  padding-left: 1rem;
}

.design-section .timeline-content li {
  margin-bottom: 0.25rem;
}

.design-section .timeline-content.text-start {
  text-align: left;
}

.design-section .timeline-component {
  margin: 0px 20px 20px 20px;
}

.design-section .timeline-middle {
  position: relative;
  margin-right: 5px;
  height: 100%;
  border-right: 1px dashed #8e9191;
}

.design-section .timeline-circle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-image: linear-gradient(0deg, #ff5b00, #ff5b00, #b03e00);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.management-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-bottom: none;
}

.management-tabs .nav-item {
  flex: none;
}

.management-tabs .nav-item .nav-link {
  color: #ff5b00;
  width: 150px;
  font-size: 14px;
  border: 1px solid #ff5b00;
  border-radius: 1rem;
  padding: 10px;
}

.management-tabs .nav-item .nav-link.active {
  background-color: #ff5b00;
  color: #ffffff;
  width: 150px;
  font-size: 14px;
  border: 1px solid #ff5b00;
  border-radius: 1rem;
  padding: 10px;
}

.management-img img {
  max-height: 250px;
}

.auth-wrapper .login {
  min-height: 100vh;
}

.auth-wrapper .bg-image {
  background-size: cover;
  background-position: center top;
  position: relative;
}

.auth-wrapper .bg-image.bg-1 {
  background-image: url("./assets/auth/bg.png");
}

.auth-wrapper .bg-image.bg-2 {
  background-image: url("./assets/auth/bg-2.png");
}

.auth-wrapper .bg-image .top {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.auth-wrapper .bg-image h4 {
  position: absolute;
  top: 8rem;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.auth-wrapper .bg-image .bottom {
  position: absolute;
  bottom: -15%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 311px;
  height: 289px;
}

.auth-wrapper .bg-image .bottom.type1 {
  bottom: -15%;
}

.auth-wrapper .bg-image .bottom.type2 {
  top: 50%;
  left: 50%;
}

.auth-wrapper .bg-img {
  position: absolute;
}

.auth-wrapper .bg-img.top {
  top: 0;
  left: 0;
}

.auth-wrapper .bg-img.bottom {
  bottom: 0;
  right: 0;
}

.auth-wrapper .footer-text {
  bottom: 0;
}

.auth-wrapper .login .login-social-media .label-login {
  position: relative;
  margin: 0 auto;
  max-width: 350px;
  text-align: center;
}
.auth-wrapper .login .login-social-media .label-login:before {
  content: "";
  display: block;
  width: 80px;
  height: 2px;
  background: #dee1e1;
  left: 0;
  top: 50%;
  position: absolute;
}
.auth-wrapper .login .login-social-media .label-login:after {
  content: "";
  display: block;
  width: 80px;
  height: 2px;
  background: #dee1e1;
  right: 0;
  top: 50%;
  position: absolute;
}

#buttonGoogle {
  overflow: hidden;
}

#buttonGoogle svg {
  width: 100%;
}

.buttonFacebook {
  background-color: #fff;
  border: 1px solid #dadce0;
  display: flex;
  width: 100%;
  margin-top: 1rem;
  font-size: 14px;
  font-weight: normal;
  color: #3c4043;
  font-family: "Google Sans", arial, sans-serif;
  height: 40px;
  line-height: 26px;
}

.buttonFacebook:hover {
  border-color: #d2e3fc;
  outline: none;
  background-color: rgba(66, 133, 244, 0.04);
}

.buttonFacebook .icon-google {
  width: 20px;
  height: 20px;
  background-image: url("./assets/icons/google.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.buttonFacebook .icon-fb {
  width: 20px;
  height: 20px;
  background-image: url("./assets/icons/facebook.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.buttonFacebook .text-fb,
.buttonFacebook .text-google {
  text-align: center;
  width: 100%;
}

.hero2 {
  background: linear-gradient(180deg, #ff5b00 0%, #e45200 125.78%);
  position: relative;
  margin-bottom: 60px;
}

.hero2.big-space {
  min-height: 360px !important;
  margin-bottom: 60px !important;
}

.hero2 .img {
  min-height: 300px;
  background: url("./assets/images/hero/hero.png") no-repeat center center;
  opacity: 0.8;
  background-origin: content-box;
  background-size: contain;
}

.hero2 .hi {
  color: #ffffff;
  position: absolute;
  top: 3rem;
}

.hero2 .card-hero {
  position: absolute;
  top: 50%;
  /* bottom: -80px; */
  left: 0;
  right: 0;
  width: 100%;
  min-height: 150px;
  background-color: transparent;
  border: none;
}

.hero2 .pigijo-icon {
  position: absolute;
  right: 0;
  bottom: 75px;
  z-index: 1;
}

fieldset > div {
  padding: 0 5px;
}

fieldset > div > div {
  font-size: 13px;
}

.card-hero .nav.nav-tabs {
  border-bottom: none;
}

.card-hero .nav.nav-tabs li {
  background-color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
}

.card-hero .nav.nav-tabs li:first-child {
  border-top-left-radius: 0.5rem;
}

.card-hero .nav.nav-tabs li:last-child {
  border-top-right-radius: 0.5rem;
}

.card-hero .nav.nav-tabs li .nav-link {
  background-color: transparent;
  border: none;
  font-weight: 400;
  color: #221d23;
}

.card-hero .nav.nav-tabs li .nav-link.active {
  border: 1px solid #ff5b00;
  border-radius: 0.25rem;
  background-color: #fff5ef;
}

.card-hero .tab-content {
  background-color: #ffffff;
  height: 100%;
  border: none;
  padding: 1rem;
  box-shadow: 0 1px 15px -2px gray;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.search-flight.new fieldset {
  padding: 10px;
}

.search-flight.new fieldset input {
  padding-bottom: 10px;
  padding-left: 0;
}

.footer-main .row .mb-4:nth-child(1) {
  order: 0;
}

.footer-main .row .mb-4:nth-child(2) {
  order: 3;
}

.footer-main .row .mb-4:nth-child(3) {
  order: 4;
}

.footer-main .row .mb-4:nth-child(4) {
  order: 1;
}

.footer-main .row .mb-4:nth-child(5) {
  order: 5;
}

.footer-main .row .mb-4:nth-child(6) {
  order: 2;
}

.footer-main .row .mb-4:nth-child(7) {
  order: 6;
}

.wizard-form .step-nav {
  display: block !important;
}

.wizard-form .step-nav .container-step-nav:not(:last-child)::after {
  content: none !important;
  width: 0% !important;
  position: relative;
}

.wizard-form .step-nav .container-step-nav div {
  display: none !important;
}

.wizard-form .step-nav .container-step-nav.active .active {
  display: block !important;
}

.wizard-form .px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.search-le > div:nth-child(1) {
  order: 2;
}

.search-le > div:nth-child(2) {
  order: 1;
}

.see-all-2 {
  display: block;
}

.see-all-2.color {
  color: rgb(255, 91, 0) !important;
  background-color: #ffffff;
}

.see-all-2.color2 {
  color: #296eb4 !important;
  background-color: #eef5fb;
}

.see-all-1 {
  display: none;
}

.h5,
h5 {
  font-size: 16px;
}

.nav-link {
  font-size: 12px;
}

body {
  font-size: calc(0.75rem + 0.3vw);
}

.form-control {
  font-size: calc(0.65rem + 0.3vw);
}

.fs-4 {
  font-size: calc(1rem + 0.5vw) !important;
}

.fs-5 {
  font-size: calc(0.75rem + 0.3vw) !important;
}

.section1c {
  margin-top: -60px;
}

.section2 .nav-tabs .nav-item button {
  font-size: calc(1rem + 0.1vw) !important;
}

h4 {
  font-size: calc(1.5rem + 0.5vw);
}

h5 {
  font-size: calc(0.75rem + 0.5vw);
}

.navbar-brand img {
  width: 100px;
  height: 100%;
}

.hero2 .card-hero .nav-tabs .nav-item {
  padding: 5px;
  width: 50%;
}

.item-le {
  font-family: "Open Sans";
}

.section2 .w-75 {
  width: 100% !important;
}

.hero2 .card-hero .nav.nav-tabs li .nav-link {
  padding: 5px;
}

.font-destination {
  font-size: 1.5rem;
}

.btn {
  font-size: 12px;
}

.btn-navigate {
  width: 120px;
}

.img-product {
  height: 200px;
}

.product-container {
  max-height: 250px;
}

.location-le {
  font-family: "Open Sans";
  font-size: 12px;
}

.city-not-found {
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
}

.city-not-found img {
  margin-bottom: 3rem;
}

.menu-mobile {
  display: block;
  position: absolute;
  background-color: #00000034;
  width: 100%;
  margin: 0;
  height: 100vh;
  padding: 0;
  top: 0;
  left: 0;
}

.menu-mobile .container-menu-mobile {
  margin-left: auto;
  background-color: #fff;
  width: 90%;
  height: 100vh;
}

.menu-mobile .container-menu-mobile .menu-mobile-top {
  text-align: right;
  padding: 1rem;
}

.menu-mobile .container-menu-mobile .menu-mobile-photo .menu-mobile-img {
  text-align: center;
  margin-top: 2rem;
}

.menu-mobile .container-menu-mobile .menu-mobile-photo .menu-mobile-text {
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 14px;
}

.menu-mobile .container-menu-mobile .menu-mobile-photo .menu-mobile-img img {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  object-position: center right;
}

.menu-mobile
  .container-menu-mobile
  .menu-mobile-setting
  .menu-mobile-setting-sub {
  border-bottom: 1px solid #dee1e1;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  color: #000;
  text-decoration: none;
  font-size: 14px;
}

.menu-mobile .container-menu-mobile .btn {
  width: 100px;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px;
}

.footer-main .text-dark {
  color: #3e4141 !important;
}

.lh-base {
  font-size: 12px;
}

.login-btn {
  color: #ff5b00;
  background-color: #fff;
}

.text-grey-custom {
  color: #a2a5a5;
}

.flex-flight-button {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
}

.block-detail-search {
  display: block;
}

.mobile-version {
  display: block;
}

.desktop-version {
  display: none;
}

.mt-xl {
  margin-top: 1rem !important;
}

.check-insurance {
  width: 24px;
  height: 24px;
}

.content {
  display: none;
}

.show-content {
  display: block;
}

.tab-investor ul li {
  font-size: 16px;
  cursor: pointer;
  margin-top: auto;
  margin-right: 50px;
  font-weight: 400;
  padding: 10px 0px;
  line-height: 20px;
  margin-bottom: 0px !important;
}

#LaporanKeuangan,
#jenisRapat,
#Tahun {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.084px;
  border: solid 1px #e8edf1;
  padding: 4px 8px;
  border-radius: 4px;
  background: white;
  margin-right: 16px;
  margin-bottom: 0px;
}

.table-disclosure {
  font-weight: 500;
}

.table-disclosure thead tr,
.table-disclosure tr td {
  text-align: left;
  line-height: 35px;
}

.tab-investor ul li.active-tab {
  color: #ff5b00;
  border-bottom: 2px solid #ff5b00;
  margin-bottom: 0px;
}
.facility-icon {
  margin-left: 1rem;
}

.facility-icon img {
  width: 25px;
  height: 25px;
  background-color: #f2f2f2;
  padding: 2px;
  border-radius: 7px;
}

@media screen and (max-width: 575px) {
  .hero2 .pigijo-icon {
    right: -50px;
    bottom: 100px;
    z-index: 1;
    scale: 0.5;
  }
  .hero2 .card-hero {
    margin-left: auto;
    margin-right: auto;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  .hero2.big-space {
    height: 700px;
    margin-bottom: 450px;
  }
  .hero2 .card-hero .tab-content {
    border-top-right-radius: 0;
  }
  .hero2 .card-hero .nav.nav-tabs li .nav-link {
    width: 100%;
  }
  .hero2 .card-hero .nav.nav-tabs li .nav-link div {
    justify-content: center;
  }
  /* .hero2.big-space .hi h5 {
    font-size: 18px;
  } */

  .item-banner {
    min-width: calc(100vw - 60px);
    max-width: calc(100vw - 60px);
    margin-right: 10px;
  }

  .btn-group {
    display: none;
  }
}

@media screen and (max-width: 428px) {
  .search-product,
  .search-flight {
    margin-top: 10px !important;
  }
  .search-flight {
    display: block !important;
  }
  .hero-mobile.bg-image.position-relative {
    min-height: 700px;
    overflow: scroll;
  }
  .first-vertical {
    margin-bottom: 2rem;
  }
  .home-mobile {
    min-height: 400px;
    display: block;
    padding-bottom: 0 !important;
  }
  .section1 {
    padding-top: 20px;
  }
  .large-bottom {
    padding-top: 415px;
  }
  .navbar-collapse {
    display: flex;
  }
  .react-datepicker {
    width: auto !important;
    padding: 0;
  }
  .modal-search {
    min-width: 0px;
  }
  .mobile-receipt {
    padding: 0 !important;
  }
  .mobile-receipt .m-5 {
    margin: 10px !important;
  }
  .mobile-receipt .ms-5 {
    margin-left: 1rem !important;
  }
  .mobile-receipt .me-5 {
    margin-right: 1rem !important;
  }
  .ta-image {
    margin-bottom: 1rem;
  }
  .detail-product.ta .ps-5 {
    padding-left: 1rem !important;
  }

  .flight-info {
    margin-top: 1rem;
  }

  .detail-flight {
    display: block !important;
    align-items: center;
    text-align: center;
  }

  .header-destination {
    display: inline-block;
  }
  .body-destination {
    /* min-height: 450px; */
  }

  .overflow-card.full > * {
    width: 85%;
  }
}

@media (min-width: 429px) {
  .hero2.big-space {
    margin-bottom: 450px;
    height: 700px;
  }
  .search-product,
  .search-flight {
    margin-top: 10px !important;
  }
  .search-flight {
    display: block !important;
  }

  .header-destination {
    /* min-height: 880px; */
  }
  .body-destination {
    /* min-height: 400px; */
  }

  .section5 .tab-content .overflow-card {
    justify-content: left;
  }

  .footer-main .row .mb-4:nth-child(1) {
    order: 0;
  }

  .footer-main .row .mb-4:nth-child(2) {
    order: 3;
  }

  .footer-main .row .mb-4:nth-child(3) {
    order: 4;
  }

  .footer-main .row .mb-4:nth-child(4) {
    order: 1;
  }

  .footer-main .row .mb-4:nth-child(5) {
    order: 5;
  }

  .footer-main .row .mb-4:nth-child(6) {
    order: 2;
  }

  .footer-main .row .mb-4:nth-child(7) {
    order: 6;
  }
}

@media (min-width: 576px) {
  .hero-mobile.bg-image.position-relative {
    min-height: 700px;
    overflow: auto;
  }
  .navbar-collapse {
    display: flex;
  }
  .modal-search {
    min-width: 0;
  }

  .header-destination {
    /* min-height: 880px; */
  }
  .body-destination {
    /* min-height: 400px; */
  }
}

@media (min-width: 768px) {
  .h5,
  h5 {
    font-size: 1.75rem;
  }
  .nav-link {
    font-size: var(--bs-nav-link-font-size);
  }
  body {
    font-size: var(--bs-body-font-size);
  }

  .hero2.big-space {
    margin-bottom: 450px;
    height: 720px;
  }

  .header-destination {
    /* min-height: 830px; */
  }
  .body-destination {
    /* min-height: 350px; */
  }

  .receipt .card-body {
    max-width: 600px;
  }
  .section5 .tab-content .overflow-card {
    justify-content: center;
  }

  .footer-main .row .mb-4:nth-child(1) {
    order: 0;
  }

  .footer-main .row .mb-4:nth-child(2) {
    order: 1;
  }

  .footer-main .row .mb-4:nth-child(3) {
    order: 2;
  }

  .footer-main .row .mb-4:nth-child(4) {
    order: 3;
  }

  .footer-main .row .mb-4:nth-child(5) {
    order: 4;
  }

  .footer-main .row .mb-4:nth-child(6) {
    order: 5;
  }

  .footer-main .row .mb-4:nth-child(7) {
    order: 6;
  }

  h4 {
    font-size: 34px;
  }

  h5 {
    font-size: 24px;
  }

  .item-banner {
    min-width: 500px;
    margin-right: 20px;
    height: 100%;
  }

  .navbar-brand img {
    width: 120px;
  }

  .hero2 .card-hero .nav-tabs .nav-item {
    padding: 15px;
    width: unset;
  }

  .section2 .w-75 {
    width: 75% !important;
  }

  .item-le {
    min-width: 300px;
    max-width: 300px;
    margin-right: 20px;
  }

  .section5 .img-partners {
    width: 192px;
  }

  .item-le .card-img-top {
    height: 190px;
  }

  .item-le .package .card-body {
    height: 140px;
  }

  .section1b {
    height: 480px;
  }

  .section2 .package > img,
  .section3 .package > img {
    height: 190px;
  }

  .section4 .package > img {
    height: 490px;
  }

  .btn {
    font-size: 16px;
  }

  .img-product {
    height: 550px;
  }

  .product-container {
    max-height: 600px;
  }

  .img-destination {
    height: 540px;
  }

  .location-le {
    font-size: var(--bs-body-font-size);
  }
  .react-datepicker__month-container {
    margin-top: 0px;
  }

  .font-sans {
    font-family: "Poppins";
    font-size: var(--bs-body-font-size);
  }

  .item-important-policy {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }

  .slide-dots {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slide-dots .dots {
    margin: 0 2px;
    width: 12px;
    height: 6px;
    border-radius: 8px;
    background-color: #dee1e1;
  }

  .slide-dots .dots.active {
    background-color: #ff5b00;
    width: 24px;
  }

  .section2 .package .card-body {
    height: 140px;
  }

  .menu-mobile {
    display: none;
  }

  .select__indicator {
    padding: 8px 1px !important;
  }

  .lh-base {
    font-size: 16px;
  }

  .flex-flight-button {
    display: block;
  }

  .block-detail-search {
    display: flex;
  }

  .mobile-version {
    display: none;
  }

  .desktop-version {
    display: block;
  }

  .mt-xl {
    margin-top: 8rem !important;
  }
}

@media (min-width: 992px) {
  .hero2.big-space {
    margin-bottom: 60px;
    height: 360px;
  }
  .search-product,
  .search-flight {
    margin-top: 0px !important;
  }
  .search-flight {
    display: flex !important;
  }

  .modal-search {
    min-width: 900px;
  }

  .header-destination {
    /* min-height: 780px; */
  }
  .body-destination {
    /* min-height: 300px; */
  }
  .hero-mobile.bg-image.position-relative {
    min-height: auto;
    overflow: unset !important;
  }

  .receipt .card-body {
    max-width: 800px;
  }

  .wizard-form .step-nav {
    display: flex !important;
  }

  .wizard-form .step-nav .container-step-nav:not(:last-child)::after {
    content: "" !important;
    width: 100% !important;
    position: absolute;
  }

  .wizard-form .step-nav .container-step-nav div {
    display: block !important;
  }

  .wizard-form .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .search-le > div:nth-child(1) {
    order: 1;
  }

  .search-le > div:nth-child(2) {
    order: 2;
  }

  .see-all-2 {
    display: none;
  }

  .see-all-1 {
    display: block;
  }
}

@media (min-width: 1200px) {
  .header-destination {
    /* min-height: 780px; */
  }
  .body-destination {
    /* min-height: 200px; */
  }

  .receipt .card-body {
    max-width: 900px;
  }
}

@media (min-width: 1400px) {
  .header-destination {
    /* min-height: 800px; */
  }

  .receipt .card-body {
    max-width: 1100px;
  }
}

.ce-message-bubble {
  background-color: #ff5b00 !important;
}

.ce_message {
  font-size: 11px !important;
}

.ce-message-date-text {
  font-size: 12px !important;
}

.ce-their-message-bubble {
  background-color: navy !important;
  color: white !important;
}

.ce-chat-title-text {
  color: #ff5b00 !important;
}

.ce-chat-subtitle-text {
  color: grey !important;
  font-size: 10px !important;
}

.ql-editor {
  white-space: unset !important;
}

.ce-ice-breaker {
  opacity: 0 !important;
}

.ce-chat-subtitle-text {
  color: #fff !important;
}
